import * as React from "react"
import './styles.scss'


//     // <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="73.851" height="77.923" viewBox="0 0 73.851 77.923">
const Logo = () => {
  return (
    <svg className={'app-logo'} xmlns="http://www.w3.org/2000/svg" width="5cm" height="5cm" viewBox="0 0 50 49.999999">
      <g id="Gruppe_1">
        {/*<path id="top_square" d="M.3,29.435,73.035,29.435,36.684,58.169.3,29.435" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/>*/}
        {/*<path id="middle_square" d="M.3,29.435,73.035,29.435,36.684,58.169.3,29.435" transform="translate(0 12)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/>*/}
        {/*<path id="bottom_square" d="M.3,29.435,73.035,29.435,36.684,58.169.3,29.435" transform="translate(0 24)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/>*/}

        <path
            // sodipodi:nodetypes="ccccc"
            id="top_square"
            d="m 4.9999999,7.1842487 2.88,4.9996943 H 42.036836 l 2.949178,-4.9996943 z"
            fill="#0094aa"
            // style="fill:#0094aa;fill-opacity:1;stroke:none;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
        />
        <path
            // sodipodi:nodetypes="ccccc"
            id="middle_square"
            fill="#009481"
            // style="fill:#009481;fill-opacity:1;stroke:none;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
            d="M 24.985943,41.825388 27.86,36.879783 10.790323,7.1799999 4.9999999,7.1842487 Z" />
        <path
            // sodipodi:nodetypes="ccccc"
            id="bottom_square"
            fill="#006c8e"
            // style="opacity:0.884244;fill:#006c8e;fill-opacity:1;stroke:none;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
            d="m 44.986014,7.1842487 -5.741921,-0.00425 -17.113422,29.7000003 2.855273,4.945387 z" />

      </g>
    </svg>
  )
}
//
// const StyledLogo = styled(Logo)`
//   #top_square {
//     animation: moveTop 2s infinite alternate;
//     animation-timing-function: ease-in-out;
//   }
//
//   #middle_square {
//     animation: moveMiddle 2s infinite alternate;
//     animation-timing-function: ease-in-out;
//   }
//
//   #bottom_square {
//     animation: moveBottom 2s infinite alternate;
//     animation-timing-function: ease-in-out;
//   }
//
//   @keyframes moveTop {
//     100% {
//       transform: translateY(20px);
//     }
//   }
//
//   @keyframes moveMiddle {
//     100% {
//       transform: translateY(22px);
//     }
//   }
//
//   @keyframes moveBottom {
//     100% {
//       transform: translateY(24px);
//     }
//   }
//
//   & {
//     width: 140px;
//     height: 140px;
//     overflow: visible;
//     opacity: 0.75;
//   }
// `
//
// const AppLabel = styled.div`
  // font-family: ${props => props.theme.fontLight};
  // font-size: 22px;
  // letter-spacing: 2.4px;
  // margin-top: 40px;
  // opacity: 0.62;
  // color: white;
// `
//
// const SplashContentContainer = styled.div`
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `

// interface SplashFadeOutContainerProps {
//   hiding?: boolean
// }
//
// const SplashFadeOutContainer = styled.div<SplashFadeOutContainerProps>`
//   @keyframes fade-out-xyz {
//     0% {
//       opacity: 1;
//     }
//     100% {
//       opacity: 0;
//     }
//   }
//
//   overflow: hidden;
//
//   position: absolute;
//   top:0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//
//   z-index: 1002;
//   animation-fill-mode: forwards;
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//
//   &.did-fade-out {
//     display: none;
//   }
//
//    ${({ hiding }) => hiding && `
//      opacity: 0;
//      animation: fade-out-xyz 2.0s;
//      animation-fill-mode: forwards;
//   `}
// `

// export const SplashscreenLoadingContent = () => {
//   return (<SplashContentContainer>
//     <StyledLogo />
//     <AppLabel>SMART BARTENDER</AppLabel>
//   </SplashContentContainer>)
// }

// const getPromise = (milliseconds: number): Promise<boolean> => {
//   return new Promise<boolean>((resolve, reject) => {
//     setTimeout(() => resolve(true), milliseconds)
//   })
// }

const Splashscreen = (props) => {

  // const [hiding, setHiding] = useState<boolean>(false)

  // useEffect(() => {
  //
  //   const promise = getPromise(3000)
  //
  //   Promise.allSettled([promise])
  //   .then(() => {
  //     setHiding(true)
  //   })
  //   .catch((err) => {
  //     console.error(err)
  //     setHiding(true)
  //   })
  //
  //   const handleAnimation = (e: any) => {
  //     if (e.animationName === 'fade-out-xyz') {
  //       e.target.classList.add('did-fade-out');
  //     }
  //   }
  //
  //   document.addEventListener('animationend', handleAnimation);
  //
  //   return (): void => {
  //     document.removeEventListener('animationend', handleAnimation)
  //   }
  // }, [])



  return <div className={'splash-background'}>
      <div className={'splash-left'} />
      <div className={'splash-right'} />

      <div className={'splash-content-container'}>
        <Logo />
        <div className={'app-label'}>SMART BARTENDER</div>
        <div className={'sub-app-label'}>Coming soon...</div>
      </div>
    </div>
}

export default Splashscreen
